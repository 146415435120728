@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
}
 h1 {
    font-size: 80px;
  }
   h2 {
    font-size: 55px;
    font-weight: 600;
  }
   p {
    font-size: 30px;
    /* font-weight: 600; */
  }

@layer components {
  .text-coral-500 {
    color: #349ce7;
  }
  
  .bg-coral-100 {
    background-color: #FFF1ED;
  }
  
  .bg-coral-500 {
    background-color: #54abf3;
  }
  
  .text-coral-600 {
    color: #349de8;
  }
  
  .hover\:text-coral-500:hover {
    color: #54c9f3;
  }
}
