/* styles.css */
.landing-container h1 {
  font-size: 80px;
}
.landing-container h2 {
  font-size: 55px;
  font-weight: 600;
}
.landing-container p {
  font-size: 30px;
  /* font-weight: 600; */
}

.landing-container {
  min-height: 100vh;
  background-color: white;
  /* padding: 20px 50px; */
}

.main-content {
  width: 100%;
  /* margin: 0 auto; */
  padding: 48px 24px;
}

.content-grid {
  width: 100%;
  display: grid;
  /* align-items: center; */
  justify-content: space-between;
  grid-template-columns:1fr;
  justify-content: space-between;
  /* align-items: center; */
}

.text-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title-coral {
  color: rgb(48, 123, 243);
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 8px;  
}

.title-black {
  color: #333;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.subtitle {
  color: #666;
  font-size: 1.125rem;
  line-height: 1.5;
}

.description {
  color: #777;
  line-height: 1.6;
}

.scroll-indicator {
  display: none;
  width: 24px;
  height: 48px;
  border: 2px solid #ccc;
  border-radius: 24px;
  position: relative;
  margin-top: 32px;
}

.scroll-dot {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 8px;
  transform: translateX(-50%);
  animation: bounce 1.5s infinite;
}

.image-container {
  position: relative;
}

.circular-frame {
  /* border-radius: 50%; */
  /* overflow: hidden; */
  aspect-ratio: 1;
  padding: 32px;
}

.content-image {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  border-radius: 16px;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(8px);
  }
}

@media (min-width: 768px) {
  .content-grid {
    grid-template-columns: 1fr 1fr;
    padding: 10px 50px;
  }

  .scroll-indicator {
    display: block;
  }
  .title-coral{
    margin-top: 60px;
  }
  .title-coral,
  .title-black {
    font-size: 3rem;    
  }
}

@media (min-width: 1024px) {
  .main-content {
    padding: 48px 64px;
  }

  .title-coral,
  .title-black {
    font-size: 3.5rem;
  }
}
